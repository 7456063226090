import React, { useState, useEffect } from 'react';
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const generateSophisticatedAttackData = () => [
  { name: 'DDoS', value: Math.floor(Math.random() * 100) },
  { name: 'APT', value: Math.floor(Math.random() * 50) },
  { name: 'Zero-Day', value: Math.floor(Math.random() * 30) },
  { name: 'Ransomware', value: Math.floor(Math.random() * 80) },
  { name: 'AI-Powered', value: Math.floor(Math.random() * 40) },
  { name: 'Supply Chain', value: Math.floor(Math.random() * 60) },
  { name: 'IoT Botnet', value: Math.floor(Math.random() * 70) },
  { name: 'Cryptojacking', value: Math.floor(Math.random() * 50) },
  { name: 'Fileless Malware', value: Math.floor(Math.random() * 40) },
  { name: 'DNS Tunneling', value: Math.floor(Math.random() * 30) },
];

const generateThreatSeverityData = () => [
  { name: 'Critical', value: Math.floor(Math.random() * 20) + 5 },
  { name: 'High', value: Math.floor(Math.random() * 30) + 10 },
  { name: 'Medium', value: Math.floor(Math.random() * 40) + 20 },
  { name: 'Low', value: Math.floor(Math.random() * 50) + 30 },
];

const generateUserData = () => ({
  active: Math.floor(Math.random() * 1000) + 500,
  newLogins: Math.floor(Math.random() * 50) + 10,
  failedAttempts: Math.floor(Math.random() * 20),
});

const generateApiAttackData = () => [
  { name: 'Auth Bypass', value: Math.floor(Math.random() * 50) },
  { name: 'Rate Limiting', value: Math.floor(Math.random() * 100) },
  { name: 'Data Exposure', value: Math.floor(Math.random() * 30) },
  { name: 'Injection', value: Math.floor(Math.random() * 40) },
  { name: 'SSRF', value: Math.floor(Math.random() * 35) },
  { name: 'Broken Access', value: Math.floor(Math.random() * 45) },
];

const allMeasures = [
  { title: 'Next-gen Firewall', description: 'Deep packet inspection and threat intelligence' },
  { title: 'AI-based IPS/IDS', description: 'Machine learning-powered Intrusion Prevention/Detection' },
  { title: 'WAF', description: 'Web Application Firewall with behavior analysis' },
  { title: 'Anti-DDoS', description: 'ML-enhanced traffic scrubbing and rate limiting' },
  { title: 'EDR', description: 'Advanced Endpoint Detection and Response' },
  { title: 'SIEM', description: 'Security Information and Event Management with AI' },
  { title: 'Zero Trust Network', description: 'Continuous authentication and authorization' },
  { title: 'Quantum-safe Encryption', description: 'Post-quantum cryptography for sensitive data' },
  { title: 'Deception Technology', description: 'Honeypots and decoy systems' },
  { title: 'Threat Hunting', description: 'Proactive search for hidden threats' },
];

const COLORS = ['#FF8042', '#FFBB28', '#00C49F', '#0088FE'];

const ThreatIntelligenceSystem = () => {
  const [attackData, setAttackData] = useState(generateSophisticatedAttackData());
  const [threatSeverityData, setThreatSeverityData] = useState(generateThreatSeverityData());
  const [userData, setUserData] = useState(generateUserData());
  const [apiAttackData, setApiAttackData] = useState(generateApiAttackData());
  const [implementedMeasures, setImplementedMeasures] = useState(allMeasures.slice(0, 4));
  const [lastUpdate, setLastUpdate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setAttackData(generateSophisticatedAttackData());
      setThreatSeverityData(generateThreatSeverityData());
      setUserData(generateUserData());
      setApiAttackData(generateApiAttackData());
      setImplementedMeasures(prevMeasures => {
        const remainingMeasures = allMeasures.filter(m => !prevMeasures.includes(m));
        const newMeasure = remainingMeasures[Math.floor(Math.random() * remainingMeasures.length)];
        return [...prevMeasures.slice(1), newMeasure];
      });
      setLastUpdate(new Date());
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const recommendations = allMeasures.filter(measure => !implementedMeasures.includes(measure));

  const RedBoldHeader = ({ children }) => (
    <h2 className="text-xl font-bold text-red-600 mb-2">{children}</h2>
  );

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">Threat Intelligence System</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="border rounded p-4">
          <RedBoldHeader>Sophisticated Attacks Detected & Prevented (Real-time)</RedBoldHeader>
          <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
              <BarChart data={attackData} layout="vertical" margin={{ left: 100 }}>
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" />
                <Tooltip />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="border rounded p-4">
          <RedBoldHeader>Threat Severity Breakdown</RedBoldHeader>
          <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={threatSeverityData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {threatSeverityData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="border rounded p-4">
          <RedBoldHeader>User Activity</RedBoldHeader>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>Active Users:</span>
              <span>{userData.active}</span>
            </div>
            <div className="flex justify-between">
              <span>New Logins:</span>
              <span>{userData.newLogins}</span>
            </div>
            <div className="flex justify-between">
              <span>Failed Attempts:</span>
              <span>{userData.failedAttempts}</span>
            </div>
          </div>
        </div>
        <div className="border rounded p-4">
          <RedBoldHeader>API Attacks</RedBoldHeader>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <BarChart data={apiAttackData}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="border rounded p-4">
          <RedBoldHeader>Implemented Security Measures</RedBoldHeader>
          <ul className="space-y-2">
            {implementedMeasures.map((measure, index) => (
              <li key={index} className="flex items-start">
                <span className="mr-2 text-green-500">🛡️</span>
                <div>
                  <strong>{measure.title}</strong>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="border rounded p-4">
          <RedBoldHeader>Recommended Enhancements</RedBoldHeader>
          <ul className="space-y-2">
            {recommendations.slice(0, 3).map((rec, index) => (
              <li key={index} className="flex items-start">
                <span className="mr-2 text-yellow-500">⚠️</span>
                <div>
                  <strong>{rec.title}:</strong> {rec.description}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="border rounded p-4 bg-blue-100">
        <h3 className="font-bold">Security Status</h3>
        <p>
          All systems operational. Last updated: {lastUpdate.toLocaleTimeString()}
        </p>
      </div>
      <div className="mt-4 text-sm text-gray-500 flex items-center">
        <span className="mr-1">🔄</span> Data refreshes every 5 seconds
      </div>
    </div>
  );
};

export default ThreatIntelligenceSystem;