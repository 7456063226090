import React from 'react';
import ThreatIntelligenceSystem from './ThreatIntelligenceSystem';

function App() {
  return (
    <div className="App">
      <ThreatIntelligenceSystem />
    </div>
  );
}

export default App;